<template>
    <div class="aops-user-incentive-detail">
         <div
            class="aop-detail-back"
            @click="handleBack"
        >
            <i class="iconfont aop-detail-left-back-icon">&#xe647;</i>返回
        </div>
        <div class="aop-detail-title">
            <div>明细查看</div>
            <!-- <el-button
            class="black-btn aop-export-btn"
            :loading="exportBtnLoading"
            @click="handleAssetsExport"
            >导出数据</el-button> -->
        </div>
        <el-table
        v-loading="isTableLoading"
        :data="listData"
        style="width: 100%;"
        class="data-table"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
      >
        <el-table-column
            v-for="col in tableColumns"
            :key="col.label"
            :prop="col.prop"
            :label="col.label"
            :width="col.width"
            :min-width="col.minWidth"
            :align="col.align"
            :fixed="col.fixed">
            <template slot-scope="{row}">
                <div v-if="col.prop === 'invite_type'">
                {{`${row[col.prop]}` === '1' ? "邀请":"被邀请"}}
                </div>
                <div v-else-if="col.prop === 'task_type'">
                {{`${row[col.prop]}` === '1' ? "拉新付费":"拉新注册"}}
                </div>
                <div v-else-if="col.prop === 'created_at'">{{filterDate(row[col.prop])}}</div>
                <div v-else-if="col.prop === 'award_desc'">
                {{getAwarDesc(row)}}
                </div>
                <div v-else>{{row[col.prop] || '-'}}</div>
            </template>
        </el-table-column>
      </el-table>
      <div v-if="total != 0"  class="aop-pagination">
        <el-pagination
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
</template>

<script>
import { formatDate } from 'ecoplants-lib';
import Api from '@/api/promotions';

export default {
    /**
     * data
     * @returns {Object}
    */
    data() {
        return {
            isTableLoading: false,
            exportBtnLoading: false,
            tableColumns: [
                { prop: 'username', label: '用户名称', width: 220, align: 'left', fixed: 'left' },
                { prop: 'activity_title', label: '活动名称', width: 240, align: 'left' },
                { prop: 'task_type', label: '任务', width: 180, align: 'left' },
                { prop: 'invite_type', label: '邀请/被邀请', width: 180, align: 'left' },
                { prop: 'relevance_username', label: '关联用户名称', width: 180, align: 'left' },
                { prop: 'created_at', label: '时间', width: 240, align: 'left' },
                { prop: 'award_desc', label: '获得奖励', minWidth: 200, align: 'left' }
            ],
            currentPage: 1,
            pageSize: 10,
            total: 0,
            listData: [],
            id: ''
        };
    },
    /**
     * mounted
    */
    mounted() {
        this.init();
    },
    methods: {
        /**
         * 获取邀请详情
        */
        init() {
            const query = this.$route.query;
            this.isTableLoading = true;
            const info = {
                limit: this.pageSize,
                page: this.currentPage,
                filter: {
                    customer_id: query.id,
                    activity_id: query.activity_id
                }
            };

            Api.getUserIncentiveInfo(info).then((res) => {
                if (res.data.code === 10200) {
                    this.total = res.data.data.total;
                    this.currentPage = res.data.data.page;
                    this.listData = res.data.data.items;
                } else {
                    this.$$error(res.data.message);
                }
                this.isTableLoading = false;
            });
        },
        /**
         * 导出
         */
        handleAssetsExport() {
            this.exportBtnLoading = true;
            const query = this.$route.query;
            const data = {
                filter: {
                    customer_id: query.id,
                    activity_id: query.activity_id
                }
            };
            try {
                Api.exportUserIncentiveInfoList(data).then((res) => {
                    if (res?.data?.code === 10200) {
                        const url = res.data.data.export_url;
                        if (url) {
                            this.downloadFile(url);
                        }
                    } else {
                        this.$$error(res.data.message);
                    }
                    this.exportBtnLoading = false;
                });
            } catch (error) {
                this.$$error(error);
                this.exportBtnLoading = false;
            }
        },
        /**
         * 设置时间
         * @param {*} val 值
          @return{*}
        */
        filterDate(val) {
            return val ? formatDate(val * 1000) : '';
        },
        /**
         * 设置时间
         * @param {*} row 值
          @return{*}
        */
        getAwarDesc(row) {
            let info = '';
            if (row.score && `${row.score}` !== '0') {
                info = `+${row.score}积分`;
            }
            if (row.subscription && `${row.subscription}` !== '0') {
                info = `${info ? `${info}，` : '+'}${row.subscription}天订阅`;
            }
            return info || '-';
        },
        /**
         * 返回
        */
        handleBack() {
            this.$router.push('/promotions/user-incentive');
        },
        /**
         * 修改页面查询
         * @param {*} val 值
        */
        handleSizeChange(val) {
            this.pageSize = val;
            this.init();
        },
        /**
        * 分页查询
         * @param {*} val 值
        */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.init();
        }
    }
};
</script>

<style lang="less">
@import "./detail.less";
</style>
